$screen-lg: 991px;
$screen-md: 768px;
$screen-sm: 479px;
$screen-xs: 375px;

$primary: #1D295A;
$primary-900: #1D295A;
$primary-800: #273A72;
$primary-700: #2E437E;
$primary-600: #374D8A;
$primary-500: #3D5493;
$primary-400: #5A6DA1;
$primary-300: #7787B0;
$primary-200: #9CA8C7;
$primary-100: #C3CADE;
$primary-50: #E7EAF1;

$secondary: #D2B687;
$secondary-900: #9B3D00;
$secondary-800: #A55300;
$secondary-700: #AC6005;
$secondary-600: #B16D0B;
$secondary-500: #B5760E;
$secondary-400: #BD882F;
$secondary-300: #C49B55;
$secondary-200: #D2B687;
$secondary-100: #E4D3B5;
$secondary-50: #F3EEE2;

$btn-success: #198754;
$btn-success-hover: #157347;

$btn-danger: #FF2828;
$btn-danger-hover: rgba(255, 40, 40, 0.8);

$success: #45BF5D;
$warning: #FFC012;
$danger: #FF2828;

$background: #F7F7F7;
$surface: #F7F7F7;
$light-gray: #ACACAC;
$dark-gray: #808080;
$line-gray: #EBEBEB;
$light-blue: #299FD2;
$color-black: #000000;
$color-white: #FFFFFF;

$bg-light: #FFFFFF;
$bg-dark: #161616;
$bg-surface: #F7F7F7;

$text-red: #FF2828;
$pink-50: #F2E3EF;

$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-26: 26px;
$size-28: 28px;
$size-30: 30px;
$size-32: 32px;
$size-34: 34px;
$size-36: 36px;
