.boxcard{
  width: 420px;
  margin: auto;
}

.boxcard .id-card {
  width: 400px;
  padding: 10px;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxcard .card-title-name{
  text-align: center;
  font-weight: bold;
  padding: 5px;
}
.boxcard .flex-card{
  display: flex;
}
.boxcard .photo {
  width: 150px;
  height: 150px;
  background-color: #eeeeee;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
}

.boxcard .details {
  text-align: left;
  padding-left: 10px;
}

.boxcard .details h2 {
  margin-bottom: 5px;
}

.boxcard .details p {
  margin: 0;
}

.font-12{
  font-size: 12px;
}

.font-10{
  font-size: 10px;
}

.font-8{
  font-size: 8px;
}

.btn-action{
  text-align: center;
  padding: 10px;
}

.page-pdf-card{
  border: 1px dotted #dddddd;
  padding: 50px
}

.box-howtouse{
  margin-top: 50px;
}

.howtouse{
  font-weight: bold;
}

.text-step-howtouse{
  padding-left: 30px;
}

.hr-line-split{
  margin-top: 50px;
  border-top: 1px dotted #a7a7a7;
}