.App {
	display: flex;
	width: 100%;
	align-items: stretch;
}

a,
a:hover,
a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
	position: fixed;
	z-index: 1;
	height: 100%;
	min-width: 100px;
	transition: all 0.5s;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
	overflow-y: auto;
	width: auto;
	@media (max-width: $screen-lg) {
		min-width: 250px;
		max-width: 250px;
		margin-left: -250px;
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
	}
}

.side-menu {
	display: flex;
	flex-direction: column;
	padding: 16px;
	.nav {
		gap: 0.125rem;
	}
	.block-profile {
		border-radius: 10px;
		padding: 16px 0;
	}
	.block-profile.active {
		background-color: $primary-700;
		color: $color-white;
	}
	.account-switch{
		border: 1px solid #eeeeee;
		padding: 5px;
		.menu.dropdown .dropdown-menu .dropdown-item {
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
}

.sidebar.is-open {
	margin-left: 0;
	min-width: 250px;
	max-width: 250px;
	transition: 0.5s;
	@media (max-width: $screen-lg) {
		min-width: 100%;
		max-width: 100%;
		margin-left: 0;
		transition: all 0.5s, height 0s;
	}
	.sidebar-header {
		padding: 16px 0;
	}
	.btn.logo-icon {
		display: none;
	}
	img.logo {
		display: block;
	}
	.label-inline {
		display: inline !important;
	}
	.btn-signout {
		display: block;
	}
	.nav-link {
		padding: 16px 8px;
		justify-content: flex-start;
	}
	.block-img-profile {
		height: 40px;
		width: 40px;
		margin-bottom: 10px;
		svg {
			height: 30px;
			width: 20px;
		}
	}
	.sidebar-footer {
		justify-content: flex-end;
		.btn {
			transform: rotate(180deg);
		}
	}
}

.sidebar-header {
	width: 100%;
	padding: 0;
}

.sidebar-footer {
	display: flex;
	justify-content: center;
	padding: 0 8px 8px;
}

.sidebar ul p {
	color: $primary-900;
	padding: 10px;
}

.menu-open {
	background: $background;
	border-radius: 10px;
}

.nav-item:hover {
	color: #7386d5;
	background: #fff;
}

.items-menu {
	display: flex;
	color: $primary-900;
	background: $background;
	border-radius: 10px;
}

li a.dropdown-toggle::after {
	display: inline-flex;
	position: relative;
	left: 60%;
	top: 10%;
	@media (max-width: $screen-lg) {
		display: inline-block;
		position: relative;
		left: 68%;
	}
}

.sidebar::-webkit-scrollbar {
	width: 3px;
}

.sidebar::-webkit-scrollbar-thumb {
	background: #5466b3;
	border-radius: 3px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
	background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
	margin-left: 100px;
	transition: all 0.3s;
	height: auto;
	padding-left: 0;
	padding-right: 0;
	min-height: calc(100vh - 60px);
	@media (max-width: $screen-lg) {
		margin-left: 0;
	}
	.navbar {
		position: fixed;
		transition: all 0.3s;
		width: calc(100% - 100px);
		@media (max-width: $screen-lg) {
			width: 100%;
		}
		z-index: 1009;
		background-color: $surface;
		padding: 16px 0 8px;
	}
}

.content.is-open {
	transition: all 0.5s;
	margin-left: 250px;
	max-width: calc(100% - 250px);
	@media (max-width: $screen-lg) {
		margin-left: 100%;
		display: none;
	}

	.navbar {
		transition: all 0.5s;
		width: calc(100% - 250px);
		@media (max-width: $screen-lg) {
			width: 100%;
		}
	}
}

section.footer.is-open {
	margin-left: 250px;
	max-width: calc(100% - 250px);
	transition: all 0.5s;
}
section.footer {
	background-color: $primary-600;
	color: #fff;
	padding: 16px 0 16px;
	margin-left: 100px;
	max-width: calc(100% - 100px);
	transition: all 0.3s;
	.container {
		margin: 0 !important;
	}
	h5 {
		line-height: 30px;
		margin-bottom: 1rem;
	}
	ul {
		list-style: none;
		padding: 0;
		line-height: 30px;
	}
	a {
		color: #fff;
		text-decoration: none;
	}
	a:hover {
		color: $secondary-200;
	}
	.icon-social a {
		margin-right: 40px;
		color: #fff;
	}
	.icon-social a:hover {
		color: $secondary-200;
	}
	.form-control {
		border: none;
		box-shadow: none;
		padding: 8px 20px;
	}
	@media (max-width: $screen-lg) {
		margin-left: unset;
		max-width: unset;
		h5 {
			margin-bottom: 0;
		}
		.accordion-button {
			background-color: $primary-900;
			color: #fff;
		}
		.accordion-item {
			background-color: transparent;
			border-color: rgba(229, 229, 229, 0.25);
		}
		.accordion-button:not(.collapsed) {
			box-shadow: none;
		}
		.icon-social a {
			margin-right: 30px;
		}
	}
	@media (max-width: 428px) {
		padding: 8px 0 8px;
		h5 {
			margin-bottom: 0;
		}
		.accordion-button {
			background-color: $primary-900;
			color: #fff;
		}
		.accordion-item {
			background-color: transparent;
			border-color: rgba(229, 229, 229, 0.25);
		}
		.accordion-button:not(.collapsed) {
			box-shadow: none;
		}
		.icon-download img {
			width: 100%;
		}
	}
}

.cookie-permission.is-open {
	margin-left: 250px;
	max-width: calc(100% - 250px);
	transition: all 0.5s;
}

.cookie-permission {
	margin-left: 100px;
	max-width: calc(100% - 100px);
	transition: all 0.3s;
	.container {
		margin: 0;
		padding: 0;
	}
}
