@font-face {
	font-family: 'Kanit';
	src: url('./kanit-light.woff2') format('woff2'), url('./kanit-light.woff') format('woff'), url('./kanit-light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Kanit-Bold';
	src: url('./kanit-semibold.woff2') format('woff2'), url('./kanit-semibold.woff') format('woff'), url('./kanit-semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}